<template>
  <div class="px-5">
    <div class="fs-24 fw-500 text-black my-3">
      <span>{{ form.name }}</span>
    </div>
    <div class="bg-white px-4 rounded-2xl mt-2" style="padding-bottom:100px">
      <div class="d-flex justify-content-end mb-3 pt-3">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          @click="handleBack"
        >
          <path
            d="M6.5293 17.9998L18.5291 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5291 17.9998L6.5293 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div>
        <div class="row">
          <div class="col-xl-6">
            <div class="text-xl text-black font-medium ml-3 mb-2 text-center">Logo</div>

            <div class>
              <form class="flex flex-col items-center justify-center cursor-pointer">
                <el-skeleton class="mb-3 mt-4" style="width: 288px">
                  <template slot="template">
                    <img
                      @click="$refs.primaryLogo.click()"
                      v-if="logoFile"
                      class="w-72 h-64 object-contain cs-border px-2"
                      :src="logoFile"
                      alt="Logo org"
                    />
                    <el-skeleton-item v-else variant="image" style="width: 288px; height: 256px" />
                  </template>
                </el-skeleton>
                <input
                  ref="primaryLogo"
                  id="file-upload"
                  class="d-none"
                  name="upload_cont_img"
                  type="file"
                  accept="image/*"
                  @change="onSelectedPrimaryLogo($event)"
                />
              </form>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="text-xl text-black font-medium ml-3 mb-2 text-center">Sub-Logo</div>

            <form class="flex flex-col items-center justify-center cursor-pointer">
              <el-skeleton class="mb-3 mt-4" style="width: 288px">
                <template slot="template">
                  <img
                    @click="$refs.subLogo.click()"
                    v-if="subLogoFile"
                    class="w-72 h-64 object-contain cs-border px-2"
                    :src="subLogoFile"
                    alt="Logo org"
                  />
                  <el-skeleton-item v-else variant="image" style="width: 288px; height: 256px" />
                </template>
              </el-skeleton>
              <input
                ref="subLogo"
                id="subLogo"
                class="d-none"
                name="upload_cont_img"
                type="file"
                accept="image/*"
                @change="onSelectedSubLogo($event)"
              />
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row mb-2">
              <div class="col">
                <label
                  class="text-xl text-black font-medium ml-3 mb-2 required"
                >{{ $t('lbl_company_name') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_company_name')"
                  v-model="form.company_name"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-6">
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_website') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_website')"
                  v-model="form.contact"
                />
              </div>
              <div class="col-xl-6">
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_hotline') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_phone')"
                  v-model="form.phone_number"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <label
                  class="text-xl text-black font-medium ml-3 mb-2"
                >{{ $t('lbl_description_detail') }}</label>
                <input
                  v-model="form.description"
                  :placeholder="$t('lbl_enter_description')"
                  class="border-0 flex items-center text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_bank') }}</label>
                <v-select
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :options="banks"
                  label="name"
                  :placeholder="$t('lbl_enter_bank_name')"
                  :reduce="option=>option.bin"
                  v-model="form.payment_bank_id"
                ></v-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-6">
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_bank_number') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_bank_number')"
                  v-model="form.payment_account_no"
                />
              </div>
              <div class="col-xl-6">
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_banker_name') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_banker_name')"
                  v-model="form.payment_account_name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mx-4 mt-4">
          <button
            v-loading="isLoading"
            @click="handleSubmitEcom"
            class="button-add text-white rounded-lg h-10 w-24 px-3 py-2 border-0 focus:outline-none"
          >{{ $t('lbl_update') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import vSelect from 'vue-select'

export default {
  name: 'OrganizationEcomUpdate',
  components: { vSelect },
  data () {
    return {
      appUtils,
      supplierInfo: {},
      form: {},
      isLoading: false,
      banks: [],
      logoFile: '',
      subLogoFile: ''
    }
  },
  created () {
    this.getSupplierInfo()
    this.getBanks()
  },

  methods: {
    onSelectedPrimaryLogo (event) {
      const file = event.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }

      this.form.logo = file
      this.logoFile = URL.createObjectURL(file)
    },
    onSelectedSubLogo (event) {
      const file = event.target.files[0]

      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.logo_sub = file
      this.subLogoFile = URL.createObjectURL(file)
    },
    async handlePostEcom () {
      if (!this.supplierInfo?.id) return
      self.isLoading = true

      try {
        if (this.form.logo && typeof this.form.logo === 'object') {
          this.form.logo = await this.uploadFile(
            this.form.logo,
            this.supplierInfo.id
          )
        }

        if (this.form.logo_sub && typeof this.form.logo_sub === 'object') {
          this.form.logo_sub = await this.uploadFile(
            this.form.logo_sub,
            this.supplierInfo.id
          )
        }

        const params = {
          ...this.form,
          name: this.form.company_name
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .postUpdateSupplierInfo(this.supplierInfo.id, params)

        this.getSupplierInfo()

        this.$toast.open({
          message: this.$t('lbl_update_success'),
          type: 'success'
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_api_error_status'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmitEcom () {
      if (!this.form.name) {
        return
      }

      this.handlePostEcom()
    },
    handleBack () {
      this.$router.go(-1)
    },
    async getSupplierInfo () {
      if (!this.$user.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getSupplierInfo(this.$user.id)

        const responseData = response.data || {}
        this.supplierInfo = responseData
        this.form = {
          name: responseData?.org_info?.name,
          company_name: responseData?.name,
          contact: responseData?.contact,
          phone_number: responseData?.phone_number,
          description: responseData?.description,
          payment_bank_id: responseData?.payment_bank_id,
          payment_account_no: responseData?.payment_account_no,
          payment_account_name: responseData?.payment_account_name,
          logo: responseData?.logo,
          logo_sub: responseData?.logo_sub
        }

        this.logoFile = responseData?.logo
        this.subLogoFile = responseData?.logo_sub
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_call_api_error'),
          type: 'error'
        })
      }
    },
    async getBanks () {
      try {
        const response = await fetch('https://api.vietqr.io/v2/banks')
        const responseData = await response.json()
        this.banks = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async uploadFile (file, id) {
      const params = {
        file,
        FolderTarget: `supplierInfo`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    }
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep {
  .button-add {
    background-color: #20419b;
  }
  .select-form {
    appearance: none;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .el-select-owner {
    input {
      font-size: 18px !important;
      color: #41464b !important;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    input::placeholder {
      font-size: 20px;
      color: #868181;
      font-weight: 400;
    }
  }

  .txt-grey-1000 {
    color: #52575c !important;
  }
  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
  option {
    display: block;
  }
}

::v-deep {
  .vs__dropdown-toggle {
    height: 100%;
  }
}

.cs-border {
  border: 1px dashed #5d5d5d;
}
</style>
  